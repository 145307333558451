module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-emotion/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-114313463-2","head":false,"cookieDomain":"negociosexitosos.com.mx"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-segment/gatsby-browser'),
      options: {"plugins":[],"writeKey":"gkXOI1PqBQwDST7ZGp9vqSsb7ybafIwt"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser'),
      options: {"plugins":[],"pixelId":"213585486190672"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    }]
